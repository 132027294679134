@import "../../styles/mixins.scss";

.pickAndMix__mithai__card__selected {
  position: absolute;
  right: -1em;
  top: -1em;
  background-color: $color_btn_second;
  z-index: 10;
  display: flex;
  border-radius: 25%;
  width: 3em;
  height: 3em;

  @include desktop() {
    width: 4em;
    height: 4em;
  }
}

.pickAndMix__mithai__card {
  background-color: $secondary_color !important;
  border-radius: 20px !important;
  height: 100%;
}

.pickAndMix__mithai__image {
  width: 60% !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  aspect-ratio: 1/1;
  margin-top: 2em;
}

.pickAndMix__mithai__card__name {
  @include typographyKomet(20px, 500, $text_color_third);
  text-align: center;
  @include desktop() {
    @include typographyKomet(23px, 500, $text_color_third);
  }
}
