@import "../../styles/mixins.scss";

.sideDrawer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  background-color: $main_color;
}

.sideDrawerTop {
  padding: 1em 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sideDrawerTopHeader {
  @include typography(25px, 400, $text_color_second);

  @include desktop() {
    @include typography(35px, 400, $text_color_second);
  }
}

.sideDrawerShoppingCartQuantity {
  @include typography(20px, 400, $text_color_second);

  @include desktop() {
    @include typography(25px, 400, $text_color_second);
  }
}

.emptyShoppingCart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  justify-content: center;
}

.emptyShoppingCartText {
  @include typography(16px, 400, $text_color_second);

  @include desktop() {
    @include typography(20px, 400, $text_color_second);
  }
}
