@import "../styles/mixins.scss";

.shopClosed {
  justify-content: space-around;
  padding-top: 1.2em;
}

.shopClosedHeader {
  @include typography(20px, 600, black);

  @include desktop() {
    @include typography(25px, 600, black);
  }
}

.shopClosedSubTitle {
  @include typography(18px, 500, black);

  @include desktop() {
    @include typography(20px, 500, black);
  }
}

.shopClosedContent {
  @include typography(16px, 400, $text_color_second);

  @include desktop() {
    @include typography(18px, 400, $text_color_second);
  }
}
