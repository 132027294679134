@import "../../../styles/mixins.scss";

//OrderLines
.orderLines {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.orderLinesEmptyCartBtn {
  @include typography(14px, 400, $danger_color);
  align-self: end;
}

.orderLineProducts {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: calc(50vh - 50px);

  @include desktop() {
    max-height: calc(55vh - 50px);
  }
}

//TotalPriceComponent
.totalPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdeff1;
  box-shadow: 0px 0px 16px rgba(41, 41, 41, 0.16);
  width: 100%;
  height: 4em;
  white-space: nowrap;
  margin-top: 1.5em;
}

.totalPriceText {
  @include typography(18px, 600, $text_color_third);
  padding: 1em;

  @include desktop() {
    @include typography(23px, 600, $text_color_third);
  }
}

//Buttons
.orderLinesButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin: 2em;
  margin-bottom: 2em;

  @include desktop() {
    flex-direction: row;
  }
}

.orderLineBtn {
  width: 100% !important;
  max-width: 300px !important;
  height: 45px !important;
  border-radius: 12px !important;
  border-color: transparent !important;
  text-transform: math-auto !important;
  white-space: nowrap;

  @include desktop() {
    width: 354px !important;
    height: 55px !important;
  }

  &.continueShoppingBtn {
    @include typography(16px, 400, $text_color_second);
    background-color: $third_color !important;

    @include desktop() {
      @include typography(21px, 400, $text_color_second);
    }
  }

  &.goToShoppingCartBtn {
    @include typography(16px, 400, white);
    background-color: $color_btn_second !important;

    @include desktop() {
      @include typography(21px, 400, white);
    }
  }
}

//EmptyCheckOut
.emptyCheckOut {
  text-align: center;
  margin-top: 3em;
}

.emptyCheckOutText {
  @include typography(25px, 600, $text_color_second);
  margin-bottom: 1em !important;

  @include desktop() {
    @include typography(50px, 600, $text_color_second);
    margin-bottom: 0.5em !important;
  }
}

//For desktop only
@include desktop() {
  .orderLinesTopBox {
    display: grid;
    grid-template-columns: 50% 35% 15%;
    align-items: center;
    box-shadow: 0px 0px 16px rgba(41, 41, 41, 0.16);
    width: 100%;
    height: 4em;
    margin-bottom: 2.5em;
  }

  .orderLinesTopText {
    @include typography(23px, 500, $text_color_third);
    text-align: left;
    fill: $text_color_third;

    &.padding {
      padding-left: 1em;
    }
  }
}
