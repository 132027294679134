@import "../../styles/mixins.scss";

.advertising__Wrappper {
  margin: 1em 0 3em 0;

  @include desktop() {
    cursor: pointer;
  }
}

.advertising_InnerWrapper {
  position: relative;
  border-radius: 1rem;
  height: 15em;
  background-image: url("../../assets/PickAndMixImage.png");
  background-size: cover;
  background-repeat: no-repeat;

  @include desktop() {
    height: 30em;
  }
}

.advertising__LeftWrapper {
  width: 90%;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.92),
    #fff 100%
  );
  box-shadow: 0px 0px 6px rgb(0 0 0 / 16%);
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 1rem;

  @include desktop() {
    width: 70%;
  }
}

.advertising__LeftInnerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.advertising__LeftHeader {
  @include typography(35px, 400, $text_color_second);
  line-height: 1.2 !important;

  @include desktop() {
    @include typography(87px, 400, $text_color_second);
  }
}

.advertising__LeftContent {
  @include typography(16px, 400, $text_color_second);

  @include desktop() {
    @include typography(24px, 400, $text_color_second);
  }
}

.advertising__button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5em 0;
  border-radius: 15px;
  background: $third_color;
  max-width: 20em;

  @include desktop() {
    max-width: 30em;
  }
}

.advertising_buttonText {
  @include typography(18px, 400, $text_color_second);

  @include desktop() {
    @include typography(24px, 400, $text_color_second);
  }
}

.advertising__photo__credit {
  @include typography(12px, 400, $text_color_second);

  @include desktop() {
    @include typography(14px, 400, $text_color_second);
  }
}
