@import "../../styles/mixins.scss";

.deleteUser {
  margin: 2em;
}

.deleteUserHeader {
  @include typography(25px, 600, black);
  padding: 0.2em;
}

.deleteUserContent {
  @include typography(18px, 400, $text_color_second);
  padding: 0.5em;
}
