@import "../../styles/mixins.scss";

.pickAndMix__upper__wrapper {
  display: flex;
  flex-direction: column;
  margin: 2em;

  @include desktop() {
    flex-direction: row;
    gap: 3em;
    padding-bottom: 1em;
  }
}

.pickAndMix__info__title {
  @include typography(25px, 400, $text_color_second);

  @include desktop() {
    @include typography(32px, 400, $text_color_second);
  }
}

.pickAndMix__info__subtitle {
  @include typography(18px, 400, $text_color_second);
  padding: 0.5em 0;

  @include desktop() {
    @include typography(24px, 400, $text_color_second);
  }
}

.pickAndMix__selected__outside__wrapper {
  display: grid;
  gap: 1em;
  padding: 1em;
  border-radius: 15px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  background: $main_color;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));

  @include desktop() {
    grid-template-columns: 1fr 1.5fr;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

.pickAndMix__select__placeholder {
  @include typography(16px, 400, $text_color_second);
  text-align: center;

  @include desktop() {
    @include typography(19px, 400, $text_color_second);
    grid-row: 1;
  }
}

.pickAndMix__selected__inside__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;

  @include desktop() {
    grid-row: 1;
  }
}

.pickAndMix__selected__items {
  @include typography(16px, 400, $text_color_second);
  display: flex;
  gap: 0.3em;
  align-items: center;

  @include desktop() {
    @include typography(19px, 400, $text_color_second);
    gap: 0.2em;
  }
}

.pickAndMix__quantity__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  background-color: $third_color;
  padding: 1em;
  border-radius: 0.5em;

  @include desktop() {
    flex-direction: row;
    padding: 1em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
}

.pickAndMix__price {
  @include typographyKomet(18px, 700, $text_color_first);
  text-align: center;

  @include desktop() {
    @include typographyKomet(22px, 700, $text_color_first);
  }
}

.pickAndMix__addBtn {
  border-radius: 6px;
  background-color: $color_btn_first !important;
  @include typographyKomet(16px, 500, $text_color_third);
  text-transform: math-auto !important;

  @include desktop() {
    @include typographyKomet(19px, 500, $text_color_third);
    flex-grow: 1;
  }

  &:disabled {
    opacity: 50%;
  }
}
