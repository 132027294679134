$main_color: #fff7f9; //pink
$secondary_color: #faf7ea; //light creamy
$third_color: #f3d8c0; // dark creamy

$text_color_first: #000000; //black
$text_color_second: #393939; // dark gray
$text_color_third: #555555; // medium gray
$text_color_fourth: #707070; //light gray

$color_btn_first: #c0f3cc; // light green
$color_btn_second: #56d674; // medium green color

$danger_color: #d30000; //red
