@import "../../styles/mixins.scss";

.product__container__card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #faf7ea !important;
  border-radius: 20px !important;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1),
    0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.1) !important;

  @include desktop() {
    row-gap: 0.5em;
  }
}

.product__container__media {
  width: 70% !important;
  margin: auto;
  margin-top: 1.5em;
  border-radius: 50%;
  aspect-ratio: 1/1;
  margin-bottom: 0;

  @include desktop() {
    width: 55% !important;
  }
}

.product__container__card__content {
  display: flex;
  flex-direction: column;
  margin: 1em;
}

.product__container__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.5em;
  gap: 0.5em;
}

.product__container__favorite__title {
  @include typographyKomet(23px, 500, $text_color_third);

  @include desktop() {
    @include typographyKomet(28px, 500, $text_color_third);
    white-space: nowrap;
  }
}

.product__container__title {
  @include typographyKomet(18px, 500, $text_color_third);
  text-align: center;

  @include desktop() {
    @include typographyKomet(23px, 500, $text_color_third);
  }
}

.product__container__price__kg {
  display: flex;
  gap: 0.3em;
  align-items: baseline;
  white-space: nowrap;
}

.product__container__favorite__price {
  @include typographyKomet(20px, 700, $text_color_first);

  @include desktop() {
    @include typographyKomet(22px, 700, $text_color_first);
  }
}

.product__container__price {
  @include typographyKomet(18px, 700, $text_color_first);

  @include desktop() {
    @include typographyKomet(22px, 700, $text_color_first);
  }
}

.product__container__favorite__unit {
  @include typographyKomet(18px, 500, $text_color_third);
}

.product__container__unit {
  @include typographyKomet(16px, 500, $text_color_third);

  @include desktop() {
    @include typographyKomet(18px, 500, $text_color_third);
  }
}

.product__container__favorite__description {
  @include typography(16px, 400, $text_color_second);
  text-align: left;
}

.product__container__description {
  @include typography(15px, 400, $text_color_second);
  text-align: left;
}

.one-line-overflow {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product__container__card__actions {
  padding: 4px 13px 20px 13px !important;
  justify-content: center;
}

.product__container__shopping__cart {
  color: #000000 !important;
  background-color: $color_btn_first;
  border-radius: 14px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  padding: 0.1em 2em;

  @include desktop() {
    padding: 0.2em 2em;
  }
}

.css-46bh2p-MuiCardContent-root {
  padding: 6px !important;
}
