@import "../../styles/mixins.scss";

.guidelines {
  background-color: #f1f4f9;
  margin: 1em;
  padding: 0.5em;

  @include desktop() {
    margin: 2em;
    padding-bottom: 1em;
  }
}

.guideLinesHeader {
  @include typography(22px, 600, black);
  padding-top: 0.5em;
  text-align: center;

  @include desktop() {
    @include typography(32px, 600, black);
  }
}

.guideLinesSubTitle {
  @include typography(18px, 600, black);
  text-align: center;

  @include desktop() {
    @include typography(28px, 600, black);
  }
}

.guideLinesContent {
  @include typography(16px, 400, $text_color_second);
  padding: 0.5em;

  @include desktop() {
    @include typography(18px, 400, $text_color_second);
  }
}
