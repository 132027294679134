@import "../../styles/mixins.scss";

.productInfoDialogWrap {
  background: $secondary_color;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
}

.productInfoDialogContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1em;

  @include desktop() {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 5em;
    padding: 64px 105px;
    width: 810px;
  }
}

.productInfoDialogImg {
  width: 173px;
  height: 173px;
  border: 0.5px solid $text_color_fourth;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @include desktop() {
    width: 273px;
    height: 273px;
  }
}

.productInfoDialogName {
  @include typographyKomet(22px, 500, $text_color_third);
  text-align: center;

  @include desktop() {
    @include typographyKomet(28px, 500, $text_color_third);
    text-align: left;
  }
}

.productInfoDialogDescription {
  @include typography(16px, 400, $text_color_second);
  padding-left: 1em;

  @include desktop() {
    @include typography(18px, 400, $text_color_second);
    padding-left: 0;
  }
}

.productInfoDialogAttributeDescription {
  @include typography(16px, 400, $text_color_second);
  padding-left: 1em;

  @include desktop() {
    padding-left: 0;
  }
}

.productInfoDialogAttributeTitle {
  @include typographyKomet(20px, 500, $text_color_third);
  white-space: nowrap;
  text-align: center;

  @include desktop() {
    @include typographyKomet(28px, 500, $text_color_third);
    text-align: left;
  }
}

.productInfoDialogPriceWrap {
  display: flex;
  align-items: baseline;
  row-gap: 1em;
  padding-left: 1em;

  @include desktop() {
    &.withMarginTop {
      margin-top: 4em;
    }
  }
}

.productInfoDialogPricesTitle {
  @include typographyKomet(18px, 500, $text_color_third);
  margin-right: auto !important;
}

.productInfoDialogUnit {
  @include typographyKomet(18px, 500, $text_color_third);
}

.productInfoDialogPricesValue {
  @include typographyKomet(18px, 700, $text_color_first);
  white-space: nowrap;

  @include desktop() {
    @include typographyKomet(22px, 700, $text_color_first);
  }
}

.textLineThrough {
  text-decoration: line-through;
  text-decoration-color: red;
}

.productInfoDialogInformationWrap {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  position: relative;
}

.productInfoDialogBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 9px;
  padding: 1em;

  @include desktop() {
    margin-top: 1em;
  }

  &.quantity {
    background: $third_color;

    @include desktop() {
      grid-row-start: 3;
    }
  }

  &.addToCart {
    background: $color_btn_first;
    border-color: transparent;

    @include desktop() {
      grid-row-start: 3;
      grid-column-start: 2;
    }
  }
}

.quantityBtnText {
  @include typographyKomet(18px, 500, $text_color_third);
}

.addToCartBtnText {
  @include typographyKomet(19px, 500, $text_color_third);
}

//makes the background blur when dialog is opened.
.css-hz1bth-MuiDialog-container {
  background-color: rgba(0, 0, 0, 0.5);
}
