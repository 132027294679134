@import "../../styles/mixins.scss";

.footer {
  background-color: #efd3d8;
  min-height: 5vh;
  bottom: 0;
  align-items: center;
  padding: 1em;

  @include desktop() {
    padding: 4.75em 6em;
  }
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1em;

  @include desktop() {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footerHeader {
  @include typography(25px, 600, $text_color_second);

  @include desktop() {
    @include typography(32px, 600, $text_color_second);
  }
}

.footerInfo {
  @include typography(18px, 400, $text_color_second);

  @include desktop() {
    @include typography(24px, 400, $text_color_second);
  }
}

.withIcon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.linkStyling {
  text-decoration: none;
  color: inherit;

  @include desktop() {
    &:hover {
      text-decoration: underline;
    }
  }
}

.copyright {
  @include typography(14px, 400, $text_color_second);
  margin-top: 2rem;
  text-decoration: underline;

  @include desktop() {
    @include typography(18px, 400, $text_color_second);
  }
}
