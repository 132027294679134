@import "../../../styles/mixins.scss";

.phoneNrText,
.verifyFormText,
.customerFormText,
.successOrderNr {
  @include typography(16px, 400, $text_color_second);

  @include desktop() {
    @include typography(19px, 400, $text_color_second);
  }
}

.phoneNrBtn,
.verifyFormBtn,
.customerFormBtn {
  @include typography(16px, 600, white);
  background-color: $color_btn_second !important;
  border-radius: 11px !important;
  padding: 0.5em !important;
  margin-top: 0.7em !important;
  text-transform: math-auto !important;
  text-align: center !important;

  @include desktop() {
    @include typography(19px, 600, white);
  }
}

.verifyFormBtn {
  margin-top: 1em !important;
}

.GDPR {
  @include typography(12px, 300, $text_color_second);
}

.GDPRHelperText {
  @include typography(10px, 300, red);
}

.phoneNrField,
.verifyFormField,
.nameTextField,
.messageTextField {
  background-color: white;
}

.successPickUpId {
  @include typography(40px, 400, $text_color_second);

  @include desktop() {
    @include typography(80px, 400, $text_color_second);
  }
}

.successBox {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

.successOrderName,
.successOrderTime {
  @include typography(20px, 400, $text_color_second);

  @include desktop() {
    @include typography(29px, 400, $text_color_second);
  }
}

.successThanksMessage {
  @include typography(18px, 400, $text_color_second);
  padding-top: 2em;

  @include desktop() {
    @include typography(20px, 400, $text_color_second);
    position: absolute;
    padding-top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 3em !important;
  }
}

/*Stepper*/

.stepper-wrapper {
  display: flex;
  @include typography(16px, 400, $text_color_second);
  margin-top: 2.5em;

  @include desktop() {
    @include typography(19px, 400, $text_color_second);
  }
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: white;
  margin-bottom: 6px;
  border: 2px solid white;
  color: $text_color_second;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
}

.active .step-counter {
  background: #fff;
  border: 2px solid #92b7fd;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
}

.done .step-counter {
  border: 2px solid $color_btn_second;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
}

/*Divider*/

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 3px solid $text_color_fourth;
  width: 65%;
  top: 25px;
  left: -33%;
  opacity: 16%;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}
