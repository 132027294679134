@import "../../styles/mixins.scss";

.navBar {
  max-width: 100%;
  background: $main_color !important;
  box-shadow: 0px 0px 16px rgba(41, 41, 41, 0.16) !important;
  position: sticky !important;
  padding-top: 0.5em;
}

.toolBar {
  justify-content: space-evenly;
}

.mobileMenu {
  background-color: $main_color !important;
  height: 100%;
  max-height: 100%;
  width: 100%;
  padding-top: 0.5em;
}

.mobileNav {
  @include typography(16px, 500, $text_color_second);
  text-align: center;
}

.logoLayOut {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 1em 0;

  @include desktop() {
    cursor: pointer;
  }
}

.navBarTitle {
  @include typographyCocoGoose(18px, 500, $text_color_second);
  white-space: nowrap;

  @include desktop() {
    @include typographyCocoGoose(40px, 500, $text_color_second);
  }
}

.shoppingCart {
  @include typography(17px, 400, $text_color_second);
  background-color: $color_btn_first;
  border-radius: 10px;
  padding-left: 23px;
  padding-right: 23px;
  height: 45px;
  position: relative;
}

.shoppingCartNav {
  display: flex;
  gap: 0.5em;
  align-items: center;
  white-space: nowrap;

  @include desktop() {
    cursor: pointer;
  }
}

.shoppingCartBadge {
  @include typographyKomet(19px, 500, $text_color_third);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  background: white;
  border: 4px solid $color_btn_first;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  left: -15px;
  transition: all 0.3s;
}

//For desktop only
@include desktop() {
  .desktopNav {
    @include typography(19px, 400, $text_color_second);
    background-color: transparent;
    white-space: nowrap;
    padding-left: 23px;
    padding-right: 23px;
  }

  .chosen {
    background: #f9c8d0;
    border-radius: 10px;
    height: 37px;
  }
}
