@import "../../styles/mixins.scss";

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 2em;

  @include desktop() {
    margin: 2em;
  }
}

.aboutTitle {
  @include typography(30px, 500, $text_color_second);

  @include desktop() {
    @include typography(45px, 500, $text_color_second);
  }
}

.aboutContentWrap {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.aboutContent {
  @include typography(18px, 400, $text_color_second);

  @include desktop() {
    @include typography(20px, 400, $text_color_second);
  }
}
