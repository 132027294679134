@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://use.typekit.net/tuu3ief.css");

@font-face {
  font-family: "cocogoosePro";
  src: url("./cocogoose-pro.light.ttf") format("truetype");
  /* Add additional font formats if necessary */
}

@mixin typography($fontSize, $fontWeight, $color) {
  font-family: "Montserrat", sans-serif !important;
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  color: $color !important;
}

@mixin typographyKomet($fontSize, $fontWeight, $color) {
  font-family: "Komet", sans-serif !important;
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  color: $color !important;
}

@mixin typographyCocoGoose($fontSize, $fontWeight, $color) {
  font-family: "cocogoosePro", sans-serif !important;
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  color: $color !important;
}
