@import "../../../styles/mixins.scss";

//OrderLine
.orderLine {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1em;

  @include desktop() {
    grid-template-columns: 16% 34% 35% 15%;
    gap: 0em;
    align-items: center;
  }
}

.orderLineProductInfo {
  white-space: nowrap;

  @include desktop() {
    margin-top: 0.5em;
    margin-left: 1.5em;
  }
}

.orderLineProductInfoName {
  @include typography(18px, 400, #1c1c1c);
  line-height: 25px;
  white-space: pre-wrap;

  @include desktop() {
    @include typography(21px, 400, #1c1c1c);
  }
}

.orderLineProductInfoPrice {
  @include typography(16px, 400, $text_color_third);
  line-height: 19px;
  display: inline-block;
}

.orderLineProductInfoDiscount {
  @include typography(14px, 400, $text_color_third);
  line-height: 18px;
  padding-left: 0.4em;
  display: inline-block;
}

.orderLineProductInfoDelteBtn {
  @include typography(14px, 400, $danger_color);
  line-height: 18px;
  position: relative;
  right: 15px;
  display: block !important;
  text-transform: capitalize !important;
}

.orderLineQuantityCounter {
  grid-row: 2;
  grid-column: 2;

  @include desktop() {
    grid-row: 1;
    grid-column: 3;
  }
}

.orderLineProductPrice {
  @include typography(18px, 600, $text_color_third);
  white-space: nowrap;
  align-self: flex-end;
  grid-row: 2;
  grid-column: 3;
  margin-right: 1em !important;

  @include desktop() {
    @include typography(23px, 600, $text_color_third);
    align-self: center;
    grid-row: 1;
    grid-column: 4;
  }
}

//MithaiBox
.orderLineMithaiBox {
  grid-column: 2 / span 2;
  margin-top: 0.5em;
}

.orderLineMithaiBoxChangeBtn {
  @include typography(14px, 400, $text_color_first);
  background: #d9f491 !important;
  width: 135px;
  height: 36px;
  border-radius: 6px !important;
  margin-bottom: 2em !important;
  text-transform: math-auto !important;
}

.orderLineMithaiBoxList {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.orderLineMithaiBoxItems {
  @include typography(15px, 400, #1c1c1c);
  border: 1px solid $text_color_fourth;
  border-radius: 6px;
  background: #fff;
  padding: 0.5em;
}

.orderLineDivider {
  grid-column: 1 / span 3;
  margin-top: 1em !important;
  margin-bottom: 1.5em !important;

  @include desktop() {
    grid-column: 2 / span 4;
    margin: 1.5em 0 !important;
  }
}
