@import "./styles/mixins.scss";

body {
  background-color: $main_color;
}

//MainPage
.main_page_title {
  @include typography(24px, 400, $text_color_second);
  text-align: center;
  padding-bottom: 1.5em;

  @include desktop() {
    @include typography(50px, 400, $text_color_second);
  }
}

.main_page_subtitle {
  @include typography(22px, 400, $text_color_second);
  text-align: center;
  padding-bottom: 1em;

  @include desktop() {
    @include typography(40px, 400, $text_color_second);
  }
}

//Chip
.chip {
  @include typographyKomet(14px, 500, #fff);
  border-radius: 15px;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: -10px;
  padding-left: 10px;
  padding-right: 10px;

  @include desktop {
    @include typographyKomet(18px, 500, #fff);
    height: 45px;
    right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

//Last ned flere produkter
.showMoreItemsButton {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
  margin: auto;
  column-gap: 10px;
}

.MuiPaper-root {
  overflow: inherit !important;
}

//Loader
.Loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

//Quantity
.quantityCounterValue {
  @include typography(16px, 500, $text_color_third);
  text-align: center;
  white-space: nowrap;

  @include desktop() {
    @include typography(19px, 500, $text_color_third);
  }
}

//Admin
.logIn {
  display: grid;
  grid-row-gap: 5px;
}

.logInButton {
  margin-top: 10px !important;
}

.productAttribute {
  display: grid;
  grid-auto-flow: column;
}

.dropZoneImages {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
  gap: 1em;
}

@media only screen and (max-width: 992px) {
  .css-1i7qt5y-MuiGrid-root {
    width: 90% !important;
  }
}
